import React from "react";
import style from "./Footer.module.css"



function Footer() {
    return(
             
        <div className={style.footer}>
            
            <img style={{
              height: "150px",
             
            }} src={"logo 2.png"} />

            <p style={{padding:"5px"}} className={style.copy}> © Copyright 2022 ||  Ultimate Darepong || All rights reserved </p>

            <p className={style.link}>
                <a href="https://darepong.ultimate-darepong.com/Privacy-Policy/" target="_blank" >
                     PRIVACY POLICY 
                     </a> </p>
        </div>
    
    
    );
}

export default Footer;