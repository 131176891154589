import React from "react";
import { useEffect } from 'react'
import style from "./Challengemode.module.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Challengemode() {
  useEffect(() => {
    AOS.init({duration:2000});
}, [])
  return (
    <div className={style.Funmode}>
      
      <div className={style.funcard}>
          
          <p >
            
            <h1 className={style.hh1}>
             
              Challenge Mode
            
            </h1>
            <p className={style.p1}>
            Accept the challenge or be prepared to lose.
            </p>
          </p>

        <div className={style.icard} data-aos="fade-left">
          <img
            className={style.img}
            src="chellenge.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
