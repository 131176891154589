import React from "react";
import style from "./Rules.module.css";

function Rules() {
  return (
    <div>
      <center>
        <div style={{ marginTop: "30px" }}>
          <h1 style={{ paddingTop: "105px" }} id="rules">
            <span className={style.title}>Rules of Game</span>
          </h1>
        </div>
      </center>
      <div className={style.rules}>
        <div className={style.rcard}>
          <p>
            <i class="fa fa-unsorted" style={{ paddingRight: "10px" }} /> Have
            fun!
          </p>

          <p>
            <i class="fa fa-unsorted" style={{ paddingRight: "10px" }} /> Find
            your limit and go beyond.
          </p>

          <p>
            <i class="fa fa-unsorted" style={{ paddingRight: "10px" }} /> The
            players decide a prize.
          </p>

          <p>
            <i class="fa fa-unsorted" style={{ paddingRight: "10px" }} /> Decide
            the teams and passing the phone around everyone has to insert some
            parameters.
          </p>

          <p>
            <i class="fa fa-unsorted" style={{ paddingRight: "10px" }} /> Beer
            pong rules: Take turns throwing the balls into cups. Each team gets
            to throw one ball per turn. The goal is throw the ball into a cup of
            the opposing team. You can throw the ball directly into a cup or
            bounce a ball off of the table into a cup.
            <br />
            We recommend to fill the glasses on the table with water and drink
            from different ones.
          </p>

          <p>
            <i class="fa fa-unsorted" style={{ paddingRight: "10px" }} /> If a
            ball lands into a cup, click on the respective cup in the app and
            select the player who has to do the dare.
          </p>

          <p>
            <i class="fa fa-unsorted" style={{ paddingRight: "10px" }} /> The
            player can do the dare and save the glass or drink and lose it.
            <br />
            If the dare doesn't fit with the environment, both teams can agree
            to change dare.
          </p>

          <p>
            <i class="fa fa-unsorted" style={{ paddingRight: "10px" }} /> Keep
            playing until one team has no cups remaining. The team who has no
            cups loses, and the other team has won the prize decided at the
            beginning.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Rules;
