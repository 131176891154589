import React from "react";
import { useEffect } from 'react'
import style from "./Hotmode.module.css"
import { AnimationOnScroll } from 'react-animation-on-scroll'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Hotmode(){
    useEffect(() => {
        AOS.init({duration:2000});
    }, [])
    return(

        <div className={style.Drinkmode}>
            <div className={style.drinkcard}>

                <div className={style.icard} data-aos="fade-right">

                    <img 
                        className={style.img}
                        src="hot.png"
                        alt=""
                    />

                </div>
                <p>
                    
                    <h1 className={style.hh1}>
                        Hot Mode
                    </h1>

                    <p className={style.p1}>
                        The temperature starts to raise. Still with clothes on.
                    </p>
                    
                </p>  
            </div>
        </div>
    );

}
export default Hotmode;