import React, { useEffect, useState } from "react";
import style from "./Navebar.module.css";
import { useNavigate, useLocation } from "react-router-dom";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function Navebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const { height, width } = useWindowDimensions();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    // console.log(position);
    setScrollPosition(position);
  };

  const [isOpen, setIsOpen] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setPath(location.pathname);
  });

  return (
    <div
      className={`${style.topNav} ${
        path == "/overview" ||  scrollPosition > 50 || isOpen ? style.topNavScrollView : ""
      }`}
    >
      <nav class="navbar">
        <label
          class="navbar-toggle"
          style={{ marginRight: "30px", marginTop: "30px" }}
          id="js-navbar-toggle"
          onClick={() => {
            setIsOpen(isOpen == 1 ? 0 : 1);
          }}
        >
          {path != "/overview" ? (
            isOpen ? (
              <i class="fa fa-close"></i>
            ) : (
              <i class="fa fa-bars"></i>
            )
          ) : (
            <span />
          )}
        </label>
        <a href="#" class="logo">
          <img src="logo 2.png" style={{ height: "80px",marginTop: "10px", }} />
        </a>

        {/* <input type="checkbox" id="chkToggle"></inpu  t> */}

        {path != "/overview" ? (
          isOpen || width > 768 ? (
            <ul class="main-nav">
              <li>
                <a
                  href="#"
                  class="nav-links"
                  onClick={() => {
                    setPath(
                      location.pathname == "/overview" ? "/overview" : ""
                    );
                    setIsOpen(isOpen == 1 ? 0 : 1);
                  }}
                >
                  HOME
                </a>
              </li>
              <li>
                <a
                  href="#about-us"
                  class="nav-links"
                  onClick={() => {
                    setPath(
                      location.pathname == "/overview" ? "/overview" : ""
                    );
                    setIsOpen(isOpen == 1 ? 0 : 1);
                  }}
                >
                  ABOUT
                </a>
              </li>
              <li>
                <a class="nav-links">
                  <button
                    onClick={() => {
                      navigate("/overview");
                      setPath(
                        location.pathname == "/overview" ? "/overview" : ""
                      );
                      setIsOpen(isOpen == 1 ? 0 : 1);
                    }}
                  >
                    OVERVIEW
                  </button>
                </a>
              </li>
              <li>
                <a
                  href="#rules"
                  class="nav-links"
                  onClick={() => {
                    setPath(
                      location.pathname == "/overview" ? "/overview" : ""
                    );
                    setIsOpen(isOpen == 1 ? 0 : 1);
                  }}
                >
                  RULES
                </a>
              </li>
              <li>
                <a
                  href="#modes"
                  class="nav-links"
                  onClick={() => {
                    setPath(
                      location.pathname == "/overview" ? "/overview" : ""
                    );
                    setIsOpen(isOpen == 1 ? 0 : 1);
                  }}
                >
                  MODES
                </a>
              </li>
              <li>
                <a
                  href="#logic-of-dares"
                  class="nav-links"
                  onClick={() => {
                    setPath(
                      location.pathname == "/overview" ? "/overview" : ""
                    );
                    setIsOpen(isOpen == 1 ? 0 : 1);
                  }}
                >
                  LOGIC
                </a>
              </li>
              <li>
                <a
                  href="#upcoming-features"
                  class="nav-links"
                  onClick={() => {
                    setPath(
                      location.pathname == "/overview" ? "/overview" : ""
                    );
                    setIsOpen(isOpen == 1 ? 0 : 1);
                  }}
                >
                  UPCOMING FEATURES
                </a>
              </li>
              <li>
                <a
                  href="#contact-us"
                  class="nav-links"
                  onClick={() => {
                    setPath(
                      location.pathname == "/overview" ? "/overview" : ""
                    );
                    setIsOpen(isOpen == 1 ? 0 : 1);
                  }}
                >
                  CONTACT
                </a>
              </li>
            </ul>
          ) : (
            <div></div>
          )
        ) : (
          <span />
        )}
      </nav>
    </div>
  );
}

export default Navebar;
