import React from "react";
import { useEffect } from 'react'
import style from "./Spicymode.module.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Spicymode() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, [])
  return (
    <div className={style.Funmode}>
      <div className={style.funcard}>
          <p>
            <h1 className={style.hh1}>
              Spicy Mode
            </h1>
            <p className={style.p1}>
            Live the full experience and get ready to show some skin.
            </p>
          </p>
        <div className={style.icard} data-aos="fade-left">
          <img 
            className={style.img}
            src="spicy.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
