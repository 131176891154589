import React from 'react'
import style from "./Logic.module.css";

export default function logic() {
    return (
        <div>
        <div id="logic-of-dares">
        <center>
          <h1 style={{ paddingTop: "65px" }}>
            <span className={style.title}>Logic Of Dares</span>
          </h1>
        </center>
      </div>
        <div className={style.rules}>



        <div className={style.rcard}>

            <p><i class="fa fa-unsorted" /> &nbsp; We know what you're thinking.</p>

            <p><i class="fa fa-unsorted" /> &nbsp; Beer pong is fun, but I'm not sure it's for me.</p>

            <p><i class="fa fa-unsorted" /> &nbsp; Or maybe you're thinking, "I love beer pong, but I don't want to play with a bunch of strangers."</p>

            <p><i class="fa fa-unsorted" /> &nbsp; We hear you—and we've got the solution! Our app makes the game more fun, more social, and more personalized than ever before.</p>

            <p><i class="fa fa-unsorted" /> &nbsp; We'll show you dares personalized based on your preferences: Sex, Sexual orientation, Risk, If they are interested in other players in the game</p>

            <p><i class="fa fa-unsorted" /> &nbsp; Multiple game modes (fun, drink, challenge, hot) will let you choose how hard or easy the dares should be based on how much fun you want to have. </p>

            <p><i class="fa fa-unsorted" /> &nbsp; You can also do the dare alone with another team member or with the person you like. Our secret ingredient will determine this probability so that everyone gets exactly what they want out of their beer pong experience!</p>

        </div>

    </div>
    </div>

    )
}
