import React from "react";
import style from "./About.module.css";

export default function About() {
  return (
    <div className={style.super} id="about-us">
      <div className={style.maindiv}>
        <div className={style.profile__inner}>
          <div>
            <center>
              <h1 >
                <span className={style.title}>About</span>
              </h1>
            </center>
          </div>
          <p>
            Welcome to Ultimate Liber Pater We're going to be honest: The goal
            of our app is simple, we want to help you find new friends and
            create lasting memories with people who share your interests and
            maybe make some new ones along the way!
          </p>
        </div>

        <div class={style.profile__img}>
          <img src="logo 2.png" class={style.img} />
        </div>
      </div>
    </div>
  );
}
