import React from "react";
import style from "./Drinkmode.module.css";
import { useEffect } from 'react'
import { AnimationOnScroll } from "react-animation-on-scroll";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Drinkmode() {
  useEffect(() => {
    AOS.init({duration:2000});
}, [])
  return (
    <div className={style.Drinkmode}>

      <div className={style.drinkcard}>

        <div className={style.icard} data-aos="fade-right">
          
          <img 
            className={style.img}
            src="drink.png"
            alt=""
          />
        
        </div>
          
        <p>
              
          <h1 className={style.hh1}>
              
            Drink Mode
              
          </h1>
          <p className={style.p1}>    
          Ready to get tipsy? Perfect for pre-drinks.
          </p> 
        </p>
      
      </div>
    
    </div>
  
  );

}

export default Drinkmode;
