import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Home from './Pages/Home';
import Navebar from './Component/Navebar/Navebar';
import Overview from './Component/Overview/Overview';
// import Button from './Component/Button/Button';
// import { useNavigate } from "react-router-dom";


function App() {

  
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  }, []);
  // const Navigate = useNavigate();
  
  
  return (
   
    <Router>
      <Navebar/>
      <Routes>
        <Route exact path="/" element={<Home />} />
       
        <Route exact path="/overview" element={<Overview />} />
      </Routes>
     </Router>
  );
}

export default App;