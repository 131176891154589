import React from "react";
import { useState } from 'react';
import style from "./Contactus.module.css"

function Contactus() {

  const [name, set_name] = useState("");
  const [email, set_email] = useState("");
  const [message, set_message] = useState("");
  const [isSend, set_send] = useState(false);

  let handleSubmit = async (e) => {

    e.preventDefault();

    if(!name){
      alert("Please fill your name");
    }else if(!email) {
      alert("Please provide your email");
    }else if(!message) {
      alert("Your message is everything for us.");
    }else {

      var body = new FormData();
      body.append("name", name);
      body.append("email", email);
      body.append("message", message);
      set_send(true);
      let res = await fetch("http://darepong.ultimate-darepong.com/api/contact/add", {
        method: "POST",
        body: body,

      });
      set_send(false);
      alert("Thankyou For Connecting with US");

      window.location.href = "http://ultimate-darepong.com/";
    }
  }


  return (
    <div style={{margin:"20px"}}>
      <div id="contact-us" >
        <center>
          <h1 style={{ paddingTop: "125px" }}>
            <span className={style.title}>Contact - us</span>
          </h1>
        </center>
      </div>
      <div className={style.login}>
      

        {/* <h2>Contact-Us</h2> */}
        <form onSubmit={handleSubmit} style={{ padding:"10px",paddingTop:"10px" }}>
          <div class={style.user}>
            <input type="text" value={name} id="name" name="user_name" onChange={(e) => set_name(e.target.value)} required="" />
            <label>Name</label>
          </div>
          <div class={style.user}>
            <input type="email" value={email} id="mail" name="user_email" onChange={(e) => set_email(e.target.value)} required="" />
            <label>Email</label>
          </div>
          <div class={style.user}>
            <input type="text" value={message} onChange={(e) => set_message(e.target.value)} name="message" required="" />
            <label>Message</label>
          </div>
          <center>
            <a>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <button type="submit">Submit</button>
            </a>
          </center>

        </form>
      </div>
    </div>
  );
}
export default Contactus;

