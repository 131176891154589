import React from "react";
import { useEffect } from 'react'
import style from "./Funmode.module.css"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Funmode() {
    useEffect(() => {
        AOS.init({duration:2000});
    }, [])
    return (

        <div className={style.Funmode}>
            
            <div className={style.funcard}>

                <p  >
                
                    <h1 className={style.hh1} >
                
                        Fun Mode
                
                    </h1>
                
                  <p className={style.p1}>  Get a taste of game's functionalities with fun dares. </p>
                
                </p>
               

                <div className={style.icard} data-aos="fade-left">

                    <img
                        className={style.img}
                        src="fun.png"
                        alt=""
                    />

                </div>

            </div>

        </div>


    );
}

export default Funmode;