import React from "react";
import style from "./Features.module.css";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Features() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <>
      <div id="upcoming-features">
        <center>
          <h1 style={{ paddingTop: "125px" }}>
            <span className={style.title}>Upcoming - Features</span>
          </h1>
        </center>
      </div>

      <div className={style.main}>
        <div className={style.card} data-aos="fade-up">
          <img src="extra.png" alt="Avatar" />

          <div className={style.container}>
            <h4>
              <b>New parameter: body part they are comfortable using</b>
            </h4>
          </div>
        </div>

        <div className={style.card} data-aos="fade-up">
          <img src="2.png" alt="Avatar" />

          <div className={style.container}>
            <h4>
              <b>Modes: Couple mode, Single Mode, Mixed Mode</b>
            </h4>
          </div>
        </div>

        <div className={style.card} data-aos="fade-up">
          <img src="3.png" alt="Avatar" />

          <div className={style.container}>
            <h4>
              <b>Next release will have in-app purchase</b>
            </h4>
          </div>
        </div>

        <div className={style.card} data-aos="fade-up">
          <img src="4.png" alt="Avatar" />

          <div className={style.container}>
            <h4>
              <b>Propose new thing, we are here to listen to you</b>
            </h4>
          </div>
        </div>
      </div>
    </>

  );
}
