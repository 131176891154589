import React from "react";
import style from "./Home.module.css";
import Button from "../Component/Button/Button";
import Footer from "../Component/Footer/Footer";
import Contactus from "../Component/Contactus/Contactus";
import MovingText from "react-moving-text";
import About from "../Component/About/About";
import Funmode from "../Component/Funmode/Funmode";
import Drinkmode from "../Component/Drinkmode/Drinkmode";
import "animate.css/animate.min.css";
import Challengemode from "../Component/Challengemode/Challengemode";
import Hotmode from "../Component/Hotmode/Hotmode";
import Spicymode from "../Component/Spicymode/Spciymode";
import Rules from "../Component/Rules/Rules";
import Features from "../Component/Features/Features";
import Logic from "../Component/Logic/Logic";
import {useNavigate} from "react-router-dom"

function Home() {


  return (
    <div className={style.main}>
      <center className={style.two}>
        <MovingText
          type="flipFromLeftToCenter"
          duration="2000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="2sec"
          fillMode="none"
        >
          <p className={style.title}>
            Ultimate Liber Pater
          </p>
         
            <p className={style.slogan}>
              Beer Pong With a Twist
            </p>
          
        </MovingText>

        <div style={{ marginTop: "50px" }}>
          <MovingText
            type="fadeIn"
            duration="4000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="2sec"
            fillMode="none"
          >
            <center>
            <div className={style.download}>
              <a
                href="https://play.google.com/store/apps/details?id=com.ultimate.darepong"
                target="_blank"
              >
                <Button name="Android App" />
              </a>

              <a href="#" onClick={() => alert("Work in Progress...")  }>
                <Button name="IOS App" />
              </a>

              </div>
            </center>
          </MovingText>
        </div>
      </center>


      <About />

      {/* <Overview /> */}

      <Rules />

      <center>
        <div id="modes" style={{ marginTop: "30px" }}>
          <h1 style={{ paddingTop: "105px" }}>
            <u className={style.titleMain}>Modes</u>
          </h1>
        </div>
      </center>

      <Funmode />

      <Drinkmode />

      <Challengemode />

      <Hotmode />

      <Spicymode />

      <Logic />

      <Features />

      <Contactus />

      <Footer />
    </div>
  );
}
export default Home;
