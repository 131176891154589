import React from "react";
import style from "./Overview.module.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

// import cartoon from "../../assets/cartoon.webp";

function Overview() {
  return (
    <div style={{height:"100vh", overflowX:"hidden"}}>
    <div>
        <center>
          <h1 style={{ paddingTop: "115px" }} id="overview">
            <span className={style.span} style={{ fontSize: "60px" }}>Overview</span>
          </h1>
        </center>
      </div>
      <div className={style.rules} style={{paddingRight:"35px",paddingLeft:"35px"}}>
        <div className={style.rcard}>
          
            <p>
              <i class="fa fa-unsorted" /> &nbsp; The game is simple. You either
              drink, or you don't. But it's not just about that. It's about the
              journey, and what you do with it. And it's also about what happens
              when you play with other people—the kind of people who want to
              make you laugh, or maybe even get a little bolder than usual… and
              then maybe head home together?
            </p>
          
            <p>
              <i class="fa fa-unsorted" /> &nbsp; There are 5 modes in Ultimate
              Liber Pater Fun Mode, Drink Mode, and Challenge Mode. In Fun Mode,
              all the players have fun dashers (which means they're more likely
              to say yes) but there are no stakes: if someone says no to your
              dare, they still get to drink their glass, as usual. In Drink and
              Challenge Mode, even the dare will make you remember (or not) that
              night! And in Hot and Spicy Mode… 
            </p>
         
            <p>
              <i class="fa fa-unsorted" /> &nbsp; we'll talk more about that
              later 😉
            </p>

            <p>
              <i class="fa fa-unsorted" /> &nbsp;The goal of our app is simple: we want to help you find
              new friends and create lasting memories with people who share your
              interests—and maybe make some new ones along the way!
            </p>
         
        </div>
      </div>
    </div>
  );
}
export default Overview;
